body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #272727;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.swiper-pagination-bullet {
  @media screen and (max-width: 767px) {
    opacity: 1;
  }
  @media screen and (min-width: 1200px) {
    width: 14px !important;
    height: 14px !important;
    opacity: 0.7;
  }
}

.ModalStyledBlock {
  margin-top: 30px;
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    position: absolute;
    bottom: 15px;
  }
}

.first-swiper .swiper-slide-next .slide {
  width: 268px;
  height: 367px;

  @media screen and (max-width: 767px) {
    width: 140px;
    height: 217px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    width: 230px;
    height: 326px;
  }
  @media screen and (min-width: 1600px) {
    width: 310px;
    height: 490px;
    padding: 50px 60px;
  }
}

.first-swiper .swiper-slide-next .slide-text {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: 400;
  line-height: 19px;

  @media screen and (max-width: 767px) {
    font-size: 8px;
    line-height: 11px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    line-height: 16.08px;
    font-size: 12.56px;
  }

  @media screen and (min-width: 1600px) {
    font-size: 17px;
    line-height: 25px;
  }
}

.first-swiper .swiper-slide-next .slide-position {
  margin-top: 50px;

  @media screen and (min-width: 1600px) {
    margin-top: 150px;
    font-size: 17px;
  }
}

.first-swiper .swiper-slide-next .slide-title {
  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 21px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    line-height: 26px;
    font-size: 22px;
  }
  @media screen and (min-width: 1600px) {
    font-size: 40px;
  }
}

.first-swiper .swiper-slide:not(.swiper-slide-next) {
  margin-top: 40px;
  @media screen and (max-width: 767px) {
    margin-top: 0px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    margin-top: 0px;
  }
}

.first-swiper .swiper-button-prev {
  left: 0;
}

.first-swiper .swiper-button-next {
  right: 0;
}

.swiper-pagination {
  position: absolute;
  top: 350px !important;
}

.swiper {
  position: relative;
  padding-bottom: 30px;
}

.neon-sign {
  display: flex;
  justify-content: space-between;
  margin: 55px auto;

  align-items: center;
  @media screen and (max-width: 767px) {
    margin: 28px auto;
    margin-bottom: 10px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    margin: 40px auto;
  }

  @media screen and (min-width: 1600px) {
    margin: 100px auto;
  }
}

.letter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: relative;
  box-sizing: border-box;
}

.letter img {
  max-width: 80px;
  max-height: 80px;
  object-fit: contain;
  transform-origin: center;
  position: absolute;
  @media screen and (max-width: 767px) {
    max-width: 32px;
    max-height: 32px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    max-width: 50px;
    max-height: 50px;
  }
  @media screen and (min-width: 1600px) {
    max-width: 111px;
    max-height: 111px;
  }
}

.letter .light {
  opacity: 1;
  visibility: visible;
  margin: 0;
  padding: 0;
  display: block;
  filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.7)) /* Тень */
    drop-shadow(0 0 10px white); /* Внешнее свечение */
} /* Черные буквы: начально скрыты, но участвуют в анимации */

.letter .black {
  opacity: 0;
  visibility: visible;
  filter: drop-shadow(9px 9px 4.5px #000000);
}

@keyframes changeLetterL {
  0%,
  100% {
    opacity: 1;
  }
  4%,
  12%,
  20%,
  36%,
  44%,
  52% {
    opacity: 0;
  }
  21%,
  33%,
  49%,
  65% {
    opacity: 1;
  }
}

@keyframes changeLetterI {
  0%,
  100% {
    opacity: 1;
  }
  4%,
  12%,
  20%,
  28%,
  36%,
  44%,
  52%,
  60%,
  68% {
    opacity: 0;
  }
  5%,
  17%,
  29%,
  41%,
  53%,
  61%,
  69% {
    opacity: 1;
  }
}

@keyframes changeLetter2 {
  0%,
  100% {
    opacity: 1;
  }
  4%,
  16%,
  24%,
  32%,
  40%,
  48%,
  56%,
  64%,
  72%,
  82%,
  92% {
    opacity: 0;
  }
  9%,
  23%,
  37%,
  51%,
  65%,
  77%,
  87% {
    opacity: 1;
  }
}

@keyframes changeLetterL_black {
  0%,
  100% {
    opacity: 0;
  }
  4%,
  12%,
  20%,
  36%,
  44%,
  52% {
    opacity: 1;
  }
  21%,
  33%,
  49%,
  65% {
    opacity: 0;
  }
}

@keyframes changeLetterI_black {
  0%,
  100% {
    opacity: 0;
  }
  4%,
  12%,
  20%,
  28%,
  36%,
  44%,
  52%,
  60%,
  68% {
    opacity: 1;
  }
  5%,
  17%,
  29%,
  41%,
  53%,
  61%,
  69% {
    opacity: 0;
  }
}

@keyframes changeLetter2_black {
  0%,
  100% {
    opacity: 0;
  }
  4%,
  16%,
  24%,
  32%,
  40%,
  48%,
  56%,
  64%,
  72%,
  82%,
  92% {
    opacity: 1;
  }
  9%,
  23%,
  37%,
  51%,
  65%,
  77%,
  87% {
    opacity: 0;
  }
}

@keyframes flicker2_light {
  0%,
  100% {
    opacity: 1;
    filter: drop-shadow(0px 0px 10px #57c3ff);
  }
  20%,
  40%,
  60%,
  80% {
    opacity: 0;
    filter: drop-shadow(0px 0px 5px #57c3ff);
  }
  30%,
  50%,
  70%,
  90% {
    opacity: 1;
    filter: drop-shadow(0px 0px 15px #57c3ff);
  }
}

@keyframes flicker2_black {
  0%,
  100% {
    opacity: 0;
  }
  20%,
  40%,
  60%,
  80% {
    opacity: 1;
    filter: drop-shadow(0px 0px 15px #57c3ff);
  }
  30%,
  50%,
  70%,
  90% {
    opacity: 0;
  }
}

.letter.l .light {
  animation: changeLetterL 2s steps(1) 1 forwards;
}
.letter.i .light {
  animation: changeLetterI 2s steps(1) 1 forwards;
}
.letter.num2 .light {
  animation: changeLetter2 2s steps(1) 1 forwards;
}

.letter.l .black {
  animation: changeLetterL_black 2s steps(1) 1 forwards;
}
.letter.i .black {
  animation: changeLetterI_black 2s steps(1) 1 forwards;
}
.letter.num2 .black {
  animation: changeLetter2_black 2s steps(1) 1 forwards;
}

.letter.num2 .black.flicker {
  animation: flicker2_black 1s steps(1);
}

.letter.num2 .light.flicker {
  animation: flicker2_light 1s steps(1);
}

.CircleGeos {
  @media (max-width: 768px) {
    display: none;
  }
}

.letter.c {
  margin-right: 60px;

  @media screen and (max-width: 767px) {
    margin-right: 20px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    margin-right: 33px;
  }
  @media screen and (min-width: 1600px) {
    margin-right: 80px;
  }
}

.letter.C2 {
  margin-right: 60px;
  @media screen and (max-width: 767px) {
    margin-right: 22px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    margin-right: 38px;
  }
  @media screen and (min-width: 1600px) {
    margin-right: 80px;
  }
}

.letter.K {
  margin-right: 60px;
  @media screen and (max-width: 767px) {
    margin-right: 21px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    margin-right: 35px;
  }
  @media screen and (min-width: 1600px) {
    margin-right: 80px;
  }
}

.letter.num2 {
  margin-right: 70px;

  @media screen and (max-width: 767px) {
    margin-right: 25px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    margin-right: 40px;
  }
  @media screen and (min-width: 1600px) {
    margin-right: 90px;
  }
}

.letter.D {
  margin-right: 65px;
  @media screen and (max-width: 767px) {
    margin-right: 23px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    margin-right: 37px;
  }
  @media screen and (min-width: 1600px) {
    margin-right: 85px;
  }
}
.letter.E {
  margin-right: 60px;
  @media screen and (max-width: 767px) {
    margin-right: 23px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    margin-right: 35px;
  }
  @media screen and (min-width: 1600px) {
    margin-right: 80px;
  }
}

.letter.l {
  margin-right: 40px;
  @media screen and (max-width: 767px) {
    margin-right: 11px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    margin-right: 18px;
  }
  @media screen and (min-width: 1600px) {
    margin-right: 60px;
  }
}

.letter.i {
  margin-right: 40px;
  @media screen and (max-width: 767px) {
    margin-right: 17px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    margin-right: 25px;
  }
  @media screen and (min-width: 1600px) {
    margin-right: 60px;
  }
}

.modal-open {
  overflow: hidden;
}

.custom-file-upload {
  display: inline-block;
  width: 180px;
  background-color: #0394eb;
  color: white;
  border-radius: 8px;
  cursor: pointer;
  font-weight: bold;
  transition: background 0.3s;
  margin-top: 22px;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 6px;
  font-family: Montserrat;
  font-weight: 500;
  font-size: 24px;
  line-height: 29.26px;
  letter-spacing: 0%;
  border-radius: 17px;
  @media screen and (max-width: 767px) {
    width: 128px;
    font-size: 17px;
    line-height: 20px;
  }
}

.custom-file-upload:hover {
  background-color: #005f99;
}

.custom-file-upload input {
  display: none; /* Полностью скрываем стандартный инпут */
}

.file-name {
  display: block;
  max-width: 180px;
  position: absolute;
  top: 24px;
  left: 200px;

  font-family: Montserrat;
  font-weight: 300;
  font-size: 16px;
  line-height: 19.5px;
  letter-spacing: 0%;
  text-decoration: underline;
  text-decoration-style: solid;
  text-decoration-thickness: Auto;
}

.swiper-container {
  overflow: visible !important;
}
.swiper-wrapper {
  display: flex !important;
}

.ManagerText {
  margin-top: 5px;
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    margin-top: 12px;
  }
  @media screen and (min-width: 1200px) and (max-width: 1599px) {
    margin-top: 20px;
  }
  @media screen and (min-width: 1600px) {
    margin-top: 32px;
  }
}

.second-swiper {
  padding-bottom: 80px;
}

.swiper-button-next::after,
.swiper-button-prev::after {
  font-size: 20px;
  @media screen and (min-width: 1200px) and (max-width: 1599px) {
    font-size: 25px;
  }
  @media screen and (min-width: 1600px) {
    font-size: 30px;
  }
}

.second-swiper .swiper-pagination {
  top: 380px !important; /* Опускаем только в третьей карусели */
  @media screen and (max-width: 767px) {
    top: 190px !important;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    top: 175px !important;
  }
  @media screen and (min-width: 1200px) and (max-width: 1599px) {
    top: 380px !important;
  }
}

.third-swiper .swiper-pagination {
  top: 520px !important;
  @media screen and (max-width: 767px) {
    top: 240px !important;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
    top: 410px !important;
  }
  @media screen and (min-width: 1200px) and (max-width: 1599px) {
    top: 550px !important;
  }
}

.swiper-pagination-bullet {
  background-color: #0a0a0a;
}

.swiper-pagination-bullet-active {
  background-color: #0a0a0a;

  position: relative;
}

@media screen and (max-width: 1199px) {
  .swiper-pagination-bullet-active::after {
    content: '';
    width: 4px;
    height: 4px;
    background-color: #0091ff; /* Синий цвет */
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media screen and (min-width: 1200px) {
  .swiper-pagination-bullet-active::after {
    content: '';
    width: 7px;
    height: 7px;
    background-color: #0091ff; /* Синий цвет */
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.swiper-button-prev,
.swiper-button-next {
  color: #f0f8ff;
}
